@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin menu-padding {
	padding: 16px;
} 



@mixin edit-view {
	@include menu-padding;

	input {
		color: #4D4F53 !important;
	}
} 

@mixin open-arrow($color: #ddd) {
	            content: "";
                position: absolute;
                top: 0;
                width: 0;
                height: 0;
                margin-top: 0;
                margin-left: -8px;
                border-top: 24px solid transparent;
                border-bottom: 24px solid transparent;
                border-left: 24px solid $color;
}