/**
*
* _player-videos
*
**/

#videos {
	z-index: 2000;
	position: fixed;
	margin-left: -180px;
	left: 15%;
	bottom: 420px;
	width: 360px;
	min-width: 120px;
	min-height: 48px;
	opacity: 0.9;
}

.video.hidden {
	display: none;
}