/* Lessons */

.lessons {
    transition: .2s ease all !important;
    list-style-type: none;
    padding: 0;
    font-weight: normal;
    overflow: visible;
    border-bottom: 2px solid #b5b5b5;

    .lesson, .student-work {
        font-size: 14px;
        padding: 0px;
        background: #fff;
        list-style-type: none;
        transition: background-color 0;
        border-top: 1px solid #eee;

        #studentClasses & {
            background: #fff;
            color: #4D4F53;

            .lesson-title {
                color: #4D4F53;
            }
        }

        &.open {
            font-weight: normal;
            font-style: italic;

            &:after {
                @include open-arrow();
            }
        }


        &.active {
            &:hover {
                background: transparent !important;

                .lesson-flex {
                    background: #747678 !important;
                }
            }

            .lesson-header {
                background: #747678;
                font-weight: normal;
                padding: 8px;
                color: #fff;
                box-shadow: $menu-box-shadow;
            }
        }

        .teacher-lesson-flex,.lesson-flex, .student-flex {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding: 16px;
        }

        .teacher-lesson-flex {
            background: #747678;
        }

        .student-flex {
            padding: 16px 16px 16px 8px;
        }

        .lesson-icon {
            display: block;
            align-self: center;
            width: 24px;
            height: 24px;
            margin-right: 16px;

            .material-icons {
                width: 24px;
                color: #0A821F;
            }
        }

        .lesson-handle {
            align-self: center;
            text-align: center;
            display: inline-block;
            cursor: pointer;
            width: 36px !important;
            font-size: 18px;
            line-height: 1;
        }

        .lesson-header, .student-header {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .material-icons {
                font-size: 18px;
                margin-right: 4px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .lesson-title {
            align-self: center;
            margin-right: auto;
            line-height: 1em;
        }

        .student-title {
            align-self: center;
            margin-right: auto;
            line-height: 1em;
            font-weight: bold;
        }

        .student-info {
            align-self: center;
            margin-right: auto;
            line-height: 1em;
            font-weight: normal;
        }


        .lesson-info {
            font-weight: normal;
            color: #333;
        }

        .lesson-menu {
            i {
                vertical-align: middle;
            }
        }

        .icons {
            align-self: flex-end;
            margin-left: auto;
            white-space: nowrap;

            button {
                border: 0;
                background: transparent;

                &.open-notice {
                    cursor: not-allowed;
                }

                svg {
                    width: 20px !important;
                    height: 20px !important;
                }

            &:hover {
                svg {
                    width: 36px;
                    height: 36px;
                }
            }

            &:disabled {
                color: #999;

                svg {
                    fill: rgba(0, 0, 0, 0.27) !important;
                }
            }
            }
        }

        &.completed {
            padding-left: 0;

            .info {
                padding-left: 24px;
            }
        }

        .how-many-completed {
            font-size: 60%;
        }

        .info {
            font-size: 60%;
            float: none;
        }

        &.student {
            padding: 0 !important;
            background: #747678 !important;

            .lesson-header {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                @include menu-padding;

                transition: background-color 0;
            }

            .completed-lesson-count {
                line-height: 1;
                align-self: center;

                &.emphasize {
                    font-size: 24px;

                    .material-icons {
                        font-size: 24px;
                    }
                }
            }

            .student-lesson {
                color: #4D4F53;
                background: #fff;

                .student-lesson-title, .lesson-info {
                    line-height: 1;
                }
            }

            &.active {
                padding: 0 !important;

                &:hover {
                    background: #747678 !important;

                    .student-lesson, .lesson-flex {
                        background: #fff !important;
                    }
                }

            &:after {
                @include open-arrow();
            }
            
                .lesson-header {
                    background: #747678;
                    font-weight: normal;
                    color: #fff;
                    box-shadow: $menu-box-shadow;
                    position: relative;
                    z-index: 100;
                }
            }
        }
    }
}

/* Remote control area for lessons (on the player side) */

.lesson-control {
    color: #fff;

    button {
        font-size: 80%;
        color: #fff;
    }
}