/**
*
* _player-campaign-monitor
*
**/


.subscribe-button-inner {
	margin-top: 6px !important;
	margin-right: 24px !important;
}

@media all and (max-width: 640px) {
	.subscribe-button-inner {
	display: none !important;
	}
}