.quiz {

.textarea-wrapper {
	width: 100%;
}

textarea {
            display: inline-block;
            width: 90%;
            min-height: 400px;
            border: 2px solid #bdc3c7;
            border-radius: 6px;
            padding: 4px !important;
}

}