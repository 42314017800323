@import "exercise.scss";
@import "editor-styles.scss";

.quiz {
    max-width: 960px;
    margin: auto;
    padding: 24px;

    @media #{$bp-mobile} {
        padding: 12px !important;
    }

    .lessonTitle {
        font-size: 24px;
        margin-top: 0;
        margin-bottom: 12px;
    }

    .lessonDescription {
        display: block;
    }

    .lessonDescription img, .exerciseDescription img, .gapfillText img, .exercise-body img {
        max-width: 100% !important;
        height: auto !important;
    }

    .embeddable {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 960px;
            height: 100%;
        }
    }

    input[type=text] {
        min-width: 200px;
        padding: 8px;
        margin-bottom: 4px;
    }

    .summary {

        h4 {
            border-bottom: 1px solid #333;
        }
    }
}

fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

input:disabled, select[disabled], textarea[disabled], button:disabled {
    border-color: #ddd;
    color: #666;
    cursor: not-allowed;
}

.timer {
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border: 1px solid #666;
    border-radius: 50%;
    color: #fff;
    background: green;
    margin-right: 12px;
}
