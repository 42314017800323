.exercise {
	border: 1px solid #ddd;
	background: #fff;
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
	margin-bottom: 24px;

	header,footer {
		display: flex;
		padding: 24px !important; 


		@media #{$bp-mobile} {
			padding: 12px !important; 
        }		

		.buttons {
			margin-left: auto;
			margin-right: 16px;

		@media #{$bp-mobile} {
            margin: 0;
        }

		}
	}

	header {
		display: flex;	
		background: #f8f8f8;
		border-bottom: 1px solid #ddd;
		
		@media #{$bp-mobile} {
            flex-direction: column;
        }		

		.title {
			margin: 0 16px;
		
		@media #{$bp-mobile} {
            display: block;
            margin: 0;
            padding: 0;
        }

		}

		.timer {
			margin-left: auto;
		}		

	}


	footer {
		border-top: 1px solid #ddd;

		position: relative;

		.exercise-score {
				position: absolute;
				top: 0;
				right: -32px;
				width: 64px;
				height: 64px;
				border-radius: 32px;
				line-height: 64px;
				text-align: center;
				white-space: nowrap;
				background: #333;
				color: #fff;
		}		
	}

	.tags {
		padding: 8px;

		span {
			background: #ddd;
			color: #fff;
			padding: 4px;
			margin-left: 4px;
			border-radius: 4px;
		}
	}

	.exercise-body {
		padding: 24px;

		@media #{$bp-mobile} {
			padding: 12px !important; 
        }	

		.question-set {
			display: flex;
			position: relative;
			padding: 24px 0;


			.score {
				position: absolute;
				top: 0;
				right: -48px;
				width: 48px;
				height: 48px;
				border-radius: 24px;
				line-height: 48px;
				text-align: center;
				white-space: nowrap;
				background: #999;
				color: #fff;
			}
		}


	}
}

@import 'textarea.scss';
@import 'gapfill.scss';
@import 'matches.scss';
@import 'qa.scss';
@import 'choices.scss';

.outline-appear {
  opacity: 0.01;
}

.outline-appear.outline-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.outline-enter {
  opacity: 0.01;
  transform: scale(1.2);
}

.outline-enter.outline-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms ease-in, scale 500ms;
}

.outline-leave {
  opacity: 1;
}

.outline-leave.outline-leave-active {
  opacity: 0.01;
  transform: scale(0.1);
  transition: opacity 300ms ease-in, scale 300ms;
}