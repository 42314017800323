/**
*
* _player-scrollbar
*
**/

/* Let's get this party started */
::-webkit-scrollbar {
	padding-top: 2px;
    width: 8px;
}
 
/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2); 
    border-radius: 0;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background: #666; 
    -webkit-box-shadow: inset 0 0 2px rgba(128,128,128,0.2); 
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(192,192,192,0.2); 
}

