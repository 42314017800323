/**
*
* _player-statusbar
*
**/

#statusBar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background: #f2f2f2;
    border-top: 1px solid #ddd;
    width: 100%;
    margin: 0;
    height: 24px;
    padding: 0;
    font-size: 80%;

    #exerciseInfo, #diskSpace, #workState {

    }

    .statusSection {
        margin-right: 12px;
        padding: 0 12px;
        border-left: 1px solid #ddd;
        transition: all 0.2s;

        &:last-child {
            margin-right: 0;
        }

        &:first-child {
            border-right: 0;
        }
    }

    #appState {
        &.recording {
            color: red;
        }

        &.playing {
            color: green;
        }
    }

    #switchMode {
        button {
            margin-top: 2px;
            border: 0;
            background: transparent;
            text-transform: uppercase;
            color: #666;
        }
    }

@media #{$bp-mobile} {
    display: none;
  }

}
