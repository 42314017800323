/* Profile */

#profile {
    padding: 0;
    background: #fff;
    border-bottom: 1px solid #eee;
}

#profileImage {
    color: $sanakoRed;
    font-size: 64px;
}

#profileName {
    font-weight: bold;
    margin-bottom: 0;
}

#profileName, #profileEmail {
    margin-left: 6px;
}

.dropdown {
    display: flex;
}

#profile-menu {
    i {
        top: 30%;
    }
}
