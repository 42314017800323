.voiceinsert--label {
  margin-right: 12px;
}

paper-toggle-button {
  
  &::shadow [checked] .toggle {
    background-color: #17A32F;
  }
}



@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}

$baseFontSize: 16;

$primaryColor: #16a085;
$secondaryColor: #f2f2f2;

@function rem($val) {
  @return #{$val / $baseFontSize}rem;
}


.label--checkbox {
  position: relative;
  cursor: pointer;
}

.checkbox-old {
  position: relative;
  outline: 0;

  margin: 0 0.5rem 0 0 ;
  
  cursor: pointer;
  
  &:before {
    @include transition(transform .4s cubic-bezier(.45,1.8,.5,.75));
     @include transform(rotate(-45deg) scale(0, 0));
    
    content: "";
    
    position: absolute;
    left: rem(3);
     top: rem(2);
    z-index: 1;
  
    width: rem(12);
    height: rem(6);
    
    border: 3px solid $primaryColor; 
     border-top-style: none;
     border-right-style: none;
  }
  
  &:checked {
    &:before {
       @include transform(rotate(-45deg) scale(1, 1));
      } 
  }
  
  &:after {
    content: "";
    
    position: absolute;
    top: rem(-1);
    left: 0;
    
    width: 1rem;
    height: 1rem;
    
    background: #fff;
    
    border: 2px solid #ccc;
    
    cursor: pointer;
  }
}

