/*
 * Animation configurations ( duration and fill mode )
*/
.animate {
    -webkit-animation-duration: 6s;
    animation-duration: 6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/*
 * Animation for webkit
*/
@-webkit-keyframes bounce {
    0%, 20%, 55%, 75%, 100% {
        -webkit-transform: translateY(0);
    }

    65% {
        -webkit-transform: translateY(-20px);
    }

    80% {
        -webkit-transform: translateY(-10px);
    }
}

@keyframes bounce {
    0%, 20%, 55%, 75%, 100% {
        transform: translateY(0);
    }

    65% {
        transform: translateY(-20px);
    }

    80% {
        transform: translateY(-10px);
    }
}