/**
*
* _player-fixed-elements	
*
**/


#fixedElements {
	position: fixed; 
	bottom: 24px;
	left: 0;
	box-sizing: border-box;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	transition: all 0.5s;

	&.playerMinimized {
		transform: translate(0, 160px);
	}

	&.landscape {
		width: 33%;
		height: 100%;
		top: 48px;
		left: 67%;
		justify-content: flex-start;
		align-items: flex-start;
		align-content: flex-start;
		overflow-x: scroll; 

		background: #fff;
		box-shadow: 0 -2px 5px rgba(0,0,0,.26);
		
		.barToggle {
			display: none;
			visibility: hidden;
		}

		&.playerMinimized {
			transform: translate(0,0) !important;
		}

		.playerMinimized, #playerBar {
			transform: translate(0,0) !important;
		}

		#playerBar {
			display: flex !important;
		}

		#playerBarWrapper.playerMinimized {
			box-shadow: 0;
		}

		#playerWrapper.minimized, #playerWrapper.minimized #player, #playerBarWrapper.minimized {
			transform: translate(0,0) !important;
		}

	}
}


#playerWrapper, #playerBarWrapper, #subtitlesWrapper {
	order: 1;
	position: relative;
	width: 100%;
	min-height: 20px;
	background: transparent;
	transition: transform 0.3s ease-out;
}

#playerWrapper {
	box-shadow: 0 -2px 5px rgba(0,0,0,.26);

		&.playerBarMinimized {
			transform: translate(0, 0);
		}


	&.minimized {
	box-shadow: none;
	transform: translate(0, 0);

		&.playerBarMinimized {
			transform: translate(0, 0);
		}

		#player {
				transform: translate(0, 220px);

			&.playerBarMinimized {
				transform: translate(0, 280px);
			}
		}

	}
}

#playerBarWrapper {
	box-shadow: none;
	background: #fff;
	
	&.playerMinimized {
		box-shadow: 0 -2px 5px rgba(0,0,0,.26);
		
		transform: translate(0, -160px);
		&.minimized {
			box-shadow: none;
			background: transparent;
			transform: translate(0, -160px);
		}
	}
	
	&.minimized {
		transform: translate(0, 0);
		background: #fff;
		#playerBar {
			display: none;
			transform: translate(0, 60px);
		}

	}
}




.barToggle {
	background: #fff !important;
	z-index: 1000;
	position: absolute;
	top: -32px;
	left: 8px;
	cursor: pointer;
	transition: all 0.3s;
	
	.content {
		margin-right: 0 !important;
	}

	&:hover {
		@include icon-hover-tip();
	}

	&.minimized {

		&:hover {
			@include icon-active-hover-tip();
		}
		
		@include icon-active-state();
	}
}


@media all and (max-width: 768px) {
    .barToggle {
    	top: -16px;
    	width: 32px;
    	height: 32px;
    }
}