@mixin icon-normal-state {
	transition: all 0.3s;
    transform: rotate(0deg);
}

@mixin icon-hover-tip {
	transition: all 0.3s;
    transform: rotate(30deg);
}

@mixin icon-active-state {
    transform: rotate(180deg);
}

@mixin icon-active-hover-tip {
    transform: rotate(210deg);
}