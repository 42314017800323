/**
*
* _player-timeline	
*
**/

#player {
    width: 100%;
    background: #fff;
}

#timelineView {
    min-width: 120px;
    width: 100%;
    padding: 9;
    margin: 0 auto 12px auto;
    overflow-x: scroll;
    background: #fff;

    &::-webkit-scrollbar-track {
        border: none;
        background-color: #f2f2f2;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #4D4F53;
    }
}

#setOffsetZero {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: -16px;
}

#timelineView-old {
    width: 100%;
    padding: 0 4px 0 4px;
    margin: 24px auto 0 auto;
    overflow-x: auto;
    border-left: 1px solid #bbb;
    border-right: 1px solid #bbb;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    background: #fff;
}

#timeline {
    min-width: 100px;
    height: 100px;
    overflow: hidden;
    position: relative;
    outline: 0;
    margin: 0;
    color: #fff;
    border-left: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0;
    border-bottom: 1px solid #c0c0c0;
    background-color: #fff;
    background-image: url("/images/timeline.svg"), url("/images/timeline-bottom.svg");
    background-position: 0 0, 0 84px;
    background-repeat: repeat no-repeat, repeat no-repeat;
    transition: all 0.2s;
}

#timeline:hover {
    cursor: pointer;
}

#disabledTimeline {
    z-index: 10;
    position: absolute;
    top: 6px;
    left: 0;
    height: 80px;
    width: 100%;
    cursor: not-allowed;
    opacity: 0.5;
    background: #fff;
    transition: all 0.1s;
}

#selectedTimeline {
	display: none;
    z-index: 10;
    position: absolute;
    top: 6px;
    left: 0;
    height: 80px;
    width: 0;
    opacity: 0.1;
    background: red;
    transition: all 0.1s;
}

#caret {
    z-index: 2000;
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 12px 6px;
    border-color: transparent transparent $coolGray11 transparent;
    -webkit-transform: rotate(360deg);
    cursor: not-allowed;
}

#hoverCaret {
    z-index: 2;
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent #c0c0c0 transparent;
    -webkit-transform: rotate(360deg);
    display: none;
    cursor: not-allowed;
}

#timeline:hover #hoverCaret {
    display: inline;
}

#mousePos, #currentTimeDisplay, #maxTimeDisplay {
    font-size: 140%;
    height: 24px;
    min-height: 24px;
}

#timelineContainer {
    z-index: 1000;
}

@media all and (min-width: 640px) {
    #timelineView {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto 12px auto;
    }
}