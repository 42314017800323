/**
*
* _player-images
*
**/

#images {
	z-index: 2001;
	position: fixed;
	margin-left: -180px;
	left: 25%;
	bottom: 420px;
	width: 360px;
	min-width: 120px;
	min-height: 48px;
	opacity: 0.9;
	border-top: 6px solid $coolGray11;	
}

.image.hidden {
	display: none;
}