.timeline-player {
    .audio-load {
        margin-top: 8px;
        margin-right: 96px;
        float: right;
    }

    .load-file {
        display: none;
    }

    .load-button {
        cursor: pointer;
        padding: 4px;
        border: 2px solid #333;
    }

    .verticalSlider {
        display: inline-block;
        vertical-align: top;
        margin-top: 50px;
        transform: rotateZ(270deg);
        width: 60px !important;
    }

    .timeline-controls {
        display: inline-block;

        button {
            border: 0;
            background: #f2f2f2;
            margin-right: 2px;
            height: 24px;
        }

    }

    .timeline-panel {
        background: #fff;
    }

    .editor {
        padding: 24px;
        border-left: 1px solid #333;
        border-right: 1px solid #333;
        border-bottom: 1px solid #333;
    }

    .counters {
        display: inline-block;
        margin-left: 16px;
        width: 160px;
        font-size: 15px;

        .timeline-counter {
            font-weight: bold;
        }

        .timeline-position {
            margin: 0 24px;
        }

        .rec {
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            padding-top: 0;
            margin-top: -24px;
            margin-left: 0;
            vertical-align: middle;
            background: red;
            animation: animationFrames linear 4s;
            animation-iteration-count: infinite;
            -webkit-animation: animationFrames linear 4s;
            -webkit-animation-iteration-count: infinite;
        }

        @keyframes animationFrames {
          0% {
            transform: scale(1.0);
          }
          50% {
            transform: scale(1.8);
          }
        }
    }
}

.timeline-zoom-wrapper {
    max-width: 100%;
    padding-top: 16px;
    background: #fafafa;
    border-top: 1px solid #999;

    @media #{$bp-mobile} {
        padding-top: 4px;
    }
}

.timeline-wrapper {
    display: inline-block;
    min-width: 100px;
    max-width: calc(100% - 80px) !important;
    margin-left: 16px;
    overflow-x: scroll;
    overflow-y: hidden;
}

.timeline-player {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100vw;

    .column {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 72px);

        @media #{$bp-mobile} {
            height: calc(100vh - 48px);
        }

        &.first {
            justify-content: flex-end;
            min-width: 33vw;
            max-width: 100vw;

            .first-item {
                width: 100%;
                align-self: center;
                margin-bottom: auto;
                overflow-y: scroll;
                overflow-x: hidden;
            }
        }

        &.second {
            justify-content: flex-start;
            min-width: 0vw;
            width: 33vw;
            max-width: 66vw;
            border-left: 1px solid #999;
            background: #fff;

            .grid-item {
                .title {
                    margin: 0;
                    padding: 4px;
                    font-size: 13px;
                    background: #fafafa;
                    color: #333;
                    border-bottom: 1px solid #f2f2f2;
                }

                .body {
                    padding: 12px;
                }
            }
        }
    }

    .second-column-wrapper {
        position: relative;

        .second-column-handle {
            position: absolute !important;
            top: 0;
            right: 0;
        }
    }
}

.timeline {
    position: relative;
    width: 2000px;

    .activeRegion {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 0;
        height: 60px;
        opacity: 0.3;
        background-color: #999;
    }

    .timeline-cursor {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        margin-top: -4px;
        width: 1px;
        height: 68px;
        background: #333;
        opacity: 0.8;
    }

    .minutes {
        z-index: 0;
        height: 15px;
        width: 2000px;
        overflow: hidden;
        position: relative;
        margin-bottom: 0;

        .minuteMark {
            position: absolute;
            top: 0;
            font-size: 10px;
            -webkit-user-select: none;
            user-select: none;
            cursor: default;
            color: #999;
            text-align: center;
        }
    }

    .bookmark-navigation {
        position: relative;
        display: block;
        z-index: 1000;
        height: 20px;
        width: 100%;

        &:empty {
            height: 0;
            display: none;
        }

        .bookmark-navi {
            position: absolute;
            top: 0;
            left: 0;
            user-select: none;
            cursor: pointer;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            line-height: 20px;
            text-align: center;
            color: #fff;
            background: #999;

            &.active {
                background: #666;
            }
        }
    }

    .comment-navigation {
        position: relative;
        display: block;
        z-index: 1000;
        height: 20px;
        width: 100%;
        user-select: none;
        cursor: pointer;

        & .no-edit {
            cursor: default;
        }

        .comment-navi {
            position: absolute;
            top: 0;
            left: 0;

            user-select: none;
            cursor: pointer;
            height: 12px;
            width: 12px;
            line-height: 12px;


            &.active {


                .comment-body {
                    position: absolute;
                    top: -72px;
                    left: 0;
                    width: auto;
                    min-width: 300px;
                    max-width: 50vw;
                    height: 70px;

                    max-height: 70px;
                    border-radius: 0;

                    textarea {
                    border: 0;
                    outline: 0;

                    margin: 0;
                    font-size: 13px;
                    line-height: 14px;
                    padding: 6px 8px 4px 4px;
                    color: #fff;
                    width: calc(100% - 26px);
                    height: 70px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    color: #fff;
                    background: #333;
                    opacity: 0.7;

                    }
                }

                .delComment {
                    position: absolute;
                    top: 2px;
                    right: 2px;
                    background: transparent;
                    width: 6px;
                    height: 6px;
                }
            }
        }
    }

    .subtitle-navigation {
        position: relative;
        display: block;
        height: 20px;
        margin-bottom: 4px;
        width: 100%;

        .subtitle-navi {
            position: absolute;
            top: 0;
            left: 0;
            padding: 2px 0 0 2px;
            overflow: hidden;
            -webkit-user-select: none;
            cursor: pointer;
            width: 12px;
            height: 18px;
            line-height: 14px;
            background: #f8f8f8;

            &.active {
                background: #ddd;
            }
        }
    }

    .track-content {
        position: relative;
        width: 2000px;
        height: 60px;
        z-index: 2;
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-left: 1px solid #333;
        border-right: 1px solid #333;
        background-color: #fff;
        background-image: linear-gradient(90deg, transparent 9px, #f2f2f2 1px), linear-gradient(90deg, transparent 58px, #ddd 2px);
        background-size: 10px 60px, 60px 60px;
    }

    .timelineMinutes {
        height: 4px;
        width: 2000px;
        z-index: 0;
        border-left: 1px solid #333;
        background-color: transparent;
        background-image: linear-gradient(90deg, transparent 58px, #ddd 2px);
        background-size: 60px 4px;
    }
}

@import "controls.scss";
@import "bookmarks.scss";
@import "subtitles.scss";
@import "images.scss";
@import "track.scss";
@import "timecodeinput.scss";
