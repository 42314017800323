/**
*
* _player-tracks	
*
**/

#masterTracks {
	z-index: 1;
	position: absolute;
	top: 6px;
	left: 0;
	pointer-events: none;
	height: 40px;
	width: 100%;
	background: #f2f2f2;
}

#userTracks {
	z-index: 1;
	position: absolute;
	top: 45px;
	border-top: 1px solid #fff;
	left: 0;
	pointer-events: none;
	height: 39px;
	width: 100%;
	background: #f2f2f2;
}


/* Track */

.track {
	z-index: 1;
	position: absolute;
	top: 0;
	-webkit-box-sizing: border-box;
	pointer-events: none;
	opacity: 0.8;
	transition: all 0.2s;
}



#masterTracks .track {
	height: 40px;
	background: #4D4F53;
}

#userTracks .track {
	height: 38px;
	background: #17A32F;

}
