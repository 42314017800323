/**
*
* _player-alert
*
**/


#body-alert {
	z-index: 5000;
	display: none;
	position: fixed;
	top: 64px;
	left: 5%;
	width: 90%;
	padding: 2.4rem;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	font-size: 120%;
	font-weight: bold;
	text-align: center;
	background: #fff;
	opacity: 0.9;
	color: #333;

	&.error {
		color: red;
	}

	&.info {
		color: orange;
	}

	p {
		margin-bottom: 1.2rem;

		&.error {
		border-bottom: 2px solid red;
		background: #FFE8E8;
		}

	}

}