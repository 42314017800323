.choices {
    width: 100%;


    .single-choice {
		border: 1px solid #f2f2f2;
		padding: 4px;
        cursor: pointer;

		input {
			display: inline-block;
            margin-right: 12px;
		}

        &.correct {
                border: 2px solid green;
        }
    }
}