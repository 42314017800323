/**
*
* Helpers, in addition to Web Starter Kit's own styles.
*
**/

.center {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.nowrap {
	white-space: nowrap;
}