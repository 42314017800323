/**
*
* _player-subtitles
*
**/

#subtitles {
	z-index: 2002;
	position: fixed;
	margin-left: -180px;
	left: 50%;
	bottom: 420px;
	width: 360px;
	min-width: 120px;
	min-height: 48px;
	opacity: 0.9;
	border-top: 6px solid $coolGray11;
}

.subtitle.hidden {
	display: none;
}