.timeline-editor {
    margin-bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0;

    .audio-load {
        margin-top: 8px;
        margin-right: 96px;
        float: right;
    }

    .load-file {
        display: none;
    }

    .load-button {
        cursor: pointer;
        padding: 4px;
        border: 2px solid #333;
    }

    .verticalSlider {
        display: inline-block;
        vertical-align: top;
        margin-top: 50px;
        transform: rotateZ(270deg);
        width: 40px;
    }

    .timeline-controls {
        display: inline-block; 

        button {
            border: 0;
            background: #f2f2f2;
            margin-right: 2px;
            height: 24px;
        }

        .rec {
            color: red !important;
        }
    }

    .timeline-panel {
        background: #fafafa;
        border-bottom: 1px solid #333;
    }



        .editor {
            padding: 24px;
            border-left: 1px solid #333;
            border-right: 1px solid #333;
            border-bottom: 1px solid #333;
        }


    .counters {
        display: inline-block;
        margin-left: 16px;
        width: 160px;
        font-size: 15px;

        .timeline-counter {
            font-weight: bold;
        }

        .timeline-position {
            margin: 0 24px;
        }
    }


.timeline-zoom-wrapper {
     max-width: 100%;
       background: #fafafa;
}
.timeline-wrapper {
    display: inline-block;
    min-width: 100px;
    max-width: calc(100% - 80px) !important;
    padding-left: 12px;
    overflow-x: scroll;
    overflow-y: hidden;
}

.timeline {
    position: relative;
    width: 2000px;

    .timeline-cursor {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        margin-top: -4px;
        width: 1px;
        height: 48px;
        background: #333;
        opacity: 0.8;
    }

    .minutes {
        z-index: 0;
        height: 15px;
        width: 2000px;
        overflow: hidden;
        position: relative;
        margin-bottom: 0;

        .minuteMark {
            position: absolute;
            top: 0;
            font-size: 10px;
            -webkit-user-select: none;
            user-select: none;
            cursor: default;
            color: #999;
            text-align: center;
        }
    }

    .bookmark-navigation {
        position: relative;
        display: block;
        z-index: 1000;
        height: 20px;
        width: 100%;

        .bookmark-navi {
            position: absolute;
            top: 0;
            left: 0;
            user-select: none;
            cursor: pointer;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            line-height: 20px;
            text-align: center;
            color: #fff;
            background: #999;

            &.active {
                background: #666;
            }
        }
    }

    .subtitle-navigation {
        position: relative;
        display: block;
        height: 20px;
        margin-bottom: 4px;
        width: 100%;

        .subtitle-navi {
            position: absolute;
            top: 0;
            left: 0;
            padding: 2px 0 0 2px;
            overflow: hidden;
            -webkit-user-select: none;
            cursor: pointer;
            width: 12px;
            height: 18px;
            line-height: 14px;
            background: #f8f8f8;

            &.active {
                background: #ddd;
            }
        }
    }

    .track-content {
        position: relative;
        width: 2000px;
        height: 40px;
        z-index: 1000;
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-left: 1px solid #333;
        border-right: 1px solid #333;
        background-color: #fff;
        background-image: linear-gradient(90deg, transparent 9px, #f2f2f2 1px), linear-gradient(90deg, transparent 58px, #ddd 2px);
        background-size: 10px 40px, 60px 40px;
    }

    .timelineMinutes {
        height: 4px;
        width: 2000px;
        z-index: 0;
        border-left: 1px solid #333;
        background-color: transparent;
        background-image: linear-gradient(90deg, transparent 58px, #ddd 2px);
        background-size: 60px 4px;
    }
}

} 

@import "controls.scss";
@import "bookmarks.scss";
@import "subtitles.scss";
@import "images.scss";
@import "track.scss";
@import "timecodeinput.scss";