/**
*
* _player-contextmenu	
*
**/


.contextMenu {
	z-index: 1000;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	min-width: 64px;
	min-height: 24px;
	border: 1px solid #ccc;
	background: #fff;
	color: #333;
	font-size: 14px;
	text-indent: 0;
	margin: 0;
	padding: 0;
	list-style-type: none;

	&.hidden {
		display: none;
		visibility: none;
	}


	.contextMenuItem {
		padding: 4px 8px;
		cursor: pointer;
		list-style-type: none !important;

		&::before {
			content: '';
		}

		&:hover {
			background: #f8f8f8;
		}
	}
}