#drawerButton {
	animation-name: bounce;
    padding: 16px;
    vertical-align: middle;
    color: #fff;
    cursor: pointer;
    white-space: nowrap;
    transition: background 0.2s;

    &:hover {
        background: darken($sanakoRed, 10%);

        i {
            @include icon-hover-tip;
        }
    }

    &.is_visible {
    	background: darken($sanakoRed, 10%);
        i {
            transform: rotate(360deg);

        }

        &:hover {
        	i {

        	}
        }
    }

    i {
        vertical-align: middle;

        @include icon-normal-state;
    }
}