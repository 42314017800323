@import "_components/_variables";
@import "_components/_mixins";
@import '_components/_general';
@import "_components/_profile";
@import "_components/_classes";
@import "_components/_lessons";
@import "_components/_chat";

body {
    width: 100%;
    height: 100vh;
    position: relative;
}

main {
    width: 100%;
    height: 100%;
    padding: 5.5% 0;
    position: relative;
}

main, #header {
    transform: translateX(0);
    transition: .2s ease all !important;
}

#sefssion {
    z-index: 1000;
    display: block;
    width: 30vw !important;
    height: 100vh !important;
    overflow-y: scroll;
    overflox-x: visible;
    position: absolute;
    left: 0;
    top: 0;
    transition: .2s ease all;
}

#session {
    padding-bottom: 128px;

        .menuarea {
        padding: 24px;
        color: #333;
        background: #fff;
    }
}

.display-nav main, .display-nav .app-bar, .display-nav #fixedElements {
    transform: translateX(30vw);
}

.display-nav #nav-container {
    transform: translateX(0);
}

#bars {
    font-size: 34px;
    margin-left: 49px;
    color: #fff;
}

#bars:hover {
    color: #f2f2f2;
}

#title {
    margin: 0;
    padding: 1em;
    color: rgba(0, 0, 0, 0.4);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    font-weight: 300;
    font-size: 2em;
    font-family: 'Raleway', Arial;
}

.border {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

a.back {
    color: #38935f;
    width: 200px;
    text-decoration: none;
    text-align: center;
    font-family: 'Raleway';
    font-size: 20px;
    font-weight: 600;
    display: block;
    margin: 50px auto 0 auto;
    border: 2px solid #38935f;
    padding: 10px;
}

a.back:hover {
    color: #48b978;
    border: 2px solid #48b978;
}

.clear {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
}

span.menu-icons {
    font-size: 20px;
    height: 20px;
    width: 22px;
    float: left;
    margin: 11px 0px 10px 37px;
    color: #fff;
}

span.the-btn {
    float: right;
    font-size: 20px;
    height: 30px;
    width: 43px;
    margin-top: 10px;
    margin-right: 8px;
    padding: 0;
    color: #fff;
}

.toggle-nav {
    color: #fff !important;
    float: left !important;
}

#nav-container {
    position: fixed;
    top: 48px;
    left: 0;
    transition: .3s ease all;
    width: 30vw !important;
    overflow-x: scroll;
    color: #333;
    padding-bottom: 64px;

    &.mdl-layout__drawer {
        transform: translateX(-30vw);
        background: #f8f8f8 !important;
    }

    &.is_visible {
        transform: translateX(0) !important;

    }
}

.mdl-layout__obfuscator {
    z-index: 2500;
}

#session {
    h5.profileHeader {
        padding: 32px 32px 8px 16px !important;
        margin: 0;
        display: block;
        background: #fff;
        color: #4D4F53;
        border-bottom: $menu-border;
    }
}

#session ul {
    list-style-type: none !important;

    li::before {
        display: none;
    }
}

#loginForm, #accountForm, #passwordForm {
    padding: 16px;
}

.session-drawer-header {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0;
    background: #fff;
    border-bottom: 1px solid #eee;

    .change {
        border-top: 1px solid #eee;
        padding: 16px;
    }
}

.students {
    display: none !important;
    overflow: visible;
    padding: 0;
    transition: background-color 0 !important;

    .student-lesson {
        border-top: 1px solid #eee;
        background: #fff !important;
        opacity: 1 !important;
        padding: 16px 0;

        &.open {
            font-weight: normal;
            font-style: italic;

            &:after {

                @include open-arrow();

            }
        }
    }
}

.visible .lessons, .visible-students {
    display: block !important;
}

.material-icons.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}

.view-alert {
    padding: 16px;
    font-weight: bold;

    &.error {
        color: #ff0000;
        font-weight: bold;
    }

    &.info {
        color: #FF6A00;
    }

    &.success {
        color: #2D9900;
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(360deg);
    }

    to {
        -webkit-transform: rotate(0deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
}

#offlineNotice {
    display: block;
    width: 100%;
    position: fixed;
    padding: 1.2rem;
    font-weight: bold;
    text-align: center;
    color: red;
    background: #ddd;
    opacity: 0.9;
    z-index: 1000;

    &:empty {
        background: transparent;
        height: 0;
    }
}

.classes-title {
    text-align: center;
}

@media (max-width: 1366px) {
    #nav-container {
        width: 40vw !important;

        &.mdl-layout__drawer {
            transform: translateX(-40vw);
            background: #fff !important;
        }

        &.is_visible {
            transform: translateX(0) !important;
            //z-index: 3000;
        }
    }
}

@media (max-width: 640px) {
    #nav-container {
        width: 80vw !important;

        &.mdl-layout__drawer {
            transform: translateX(-80vw);
            background: #fff !important;
        }

        &.is_visible {
            transform: translateX(0) !important;
            //z-index: 3000;
        }
    }
}
