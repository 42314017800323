/* Classes */
/* CollectionView */

.classes {
    list-style: none;
    padding: 0 !important;

    &:not(:empty) {
        border-bottom: 1px solid #eee;
    }

    ul {
        list-style-type: none;
    }
}

/* New class */

#joinClassHeader {
    display: flex;
    cursor: pointer;
    transition: 0.2s all;
    color: #fff;
    background: #4D4F53;

    @include menu-padding;

    .handle {
        text-align: center;
        display: inline-block;
        cursor: pointer;
        width: 36px !important;
        font-size: 16px;
    }
}

#addClassForm {
    @include menu-padding;

    border-top: $menu-border;
    border-bottom: $menu-border;
}

.addClass-header {
    @include menu-padding;
    color: #fff;
    background: #4D4F53;
    cursor: pointer;
    align-self:center;
    line-height: 1;

    .handle {
        display: inline-block;
        width: 36px;
        align-self: center;
        text-align: center;
    }

    &:hover {
        background: #747678;
    }

}

#joinClassForm {
    @include menu-padding;

    border-bottom: 1px solid #eee;
}

.classes .class.visible .lessons {
    display: block;
}

.schoolName {
     display: inline-block !important;   
    font-size: 16px;
    padding: 8px 8px 2px 8px;
    margin-top: 8px;
    margin-left: 8px;
    color: #fff;
    background: #4D4F53;
    border-radius: 8px 8px 0 0;
    max-width: 50%;
}

/* Single class */
/* ClassView */
.class {
    padding: 0 !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    list-style-type: none !important;
    line-height: 1em;
    background: #4D4F53;
    color: #fff;
    transition: border 0.2s, color 0.2s, font-weight 0.2s, background-color 0;

    .class-menu {
        margin-left: auto;
    }

    .lesson-count {
        margin-left: auto;
    }

    .class-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer !important;
        color: #fff;
        font-size: 15px;

        &:hover {
            background: #747678 !important;
        }
    }

    // For flexbox to work
    .title {
        align-self: center;
        margin-right: auto;
        color: #fff;
        cursor: pointer !important;
    }

    .class-header {
        @include menu-padding;

        transition: background-color 0, color 0.2s;
    }

    .description {
        padding: 16px 16px 16px 32px;
    }

    .handle {
        text-align: center;
        display: inline-block;
        cursor: pointer;
        width: 36px !important;
        font-size: 16px;
    }

    .info {
        align-self: flex-end;
        display: inline-block;
        margin-left: auto;
        margin-right: 16px;
        font-weight: normal;
    }

    .notes {
        display: block;
        background: #fff;
        color: #333;
        width: 100%;
        padding: 0 0 12px 36px;

        &:empty {
            padding: 0;
        }

    }

    button {
        align-self: center;
    }

    .class-info {
        color: #fff;
        line-height: 1.4em;
    }

    // An active class is a class that is opened: the details and lessons are visible
    &.active {
        background: #747678;

        &:hover {
            background: #747678 !important;

            .class-header {
                background: #747678 !important;
            }
        }

        .class-header {
            background: #747678;
            font-weight: bold;
            color: #fff;
            box-shadow: $menu-box-shadow;
        }

        .class-info {
            display: block;
        }

        .handle {
            color: #fff;
            cursor: pointer !important;
        }
    }

    // An active class is a class that is opened: the details and lessons are visible
    &.open {
        background: #747678;

        &:hover {
            background: #747678 !important;

            .class-header {
                background: #747678 !important;
            }
        }

        .class-header {
            background: #747678;
            font-weight: bold;
            color: #fff;
            box-shadow: $menu-box-shadow;
            cursor: pointer !important;
        }

        .class-info {
            display: block;
        }
    }
}