/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/

/* ==========  Blacks  ========== */

$colorBlack: #000;
$colorGrayDark: rgba($colorBlack, .87);
$colorGray: rgba($colorBlack, .54);
$colorGrayLight: rgba($colorBlack, .26);
$colorGrayLighter: rgba($colorBlack, .12);

/* ==========  Color Palettes  ========== */

$paletteRed: #fde0dc #f9bdbb #f69988 #f36c60 #e84e40 #e51c23 #dd191d
           #d01716 #c41411 #b0120a #ff7997 #ff5177 #ff2d6f #e00032;

$palettePink:   #fce4ec #f8bbd0 #f48fb1 #f06292 #ec407a #e91e63 #d81b60
              #c2185b #ad1457 #880e4f #ff80ab #ff4081 #f50057 #c51162;

$palettePurple: #f3e5f5 #e1bee7 #ce93d8 #ba68c8 #ab47bc #9c27b0 #8e24aa
              #7b1fa2 #6a1b9a #4a148c #ea80fc #e040fb #d500f9 #aa00ff;

$paletteDeepPurple: #673ab7 #ede7f6 #d1c4e9 #b39ddb #9575cd #7e57c2 #673ab7
                  #5e35b1 #512da8 #4527a0 #311b92 #b388ff #7c4dff #651fff
                  #6200ea;

$paletteIndigo: #e8eaf6 #c5cae9 #9fa8da #7986cb #5c6bc0 #3f51b5 #3949ab
              #303f9f #283593 #1a237e #8c9eff #536dfe #3d5afe #304ffe;

$paletteBlue: #e7e9fd #d0d9ff #afbfff #91a7ff #738ffe #5677fc #4e6cef
            #455ede #3b50ce #2a36b1 #a6baff #6889ff #4d73ff #4d69ff;

$paletteLightBlue: #e1f5fe #b3e5fc #81d4fa #4fc3f7 #29b6f6 #03a9f4 #039be5
                 #0288d1 #0277bd #01579b #80d8ff #40c4ff #00b0ff #0091ea;

$paletteCyan: #e0f7fa #b2ebf2 #80deea #4dd0e1 #26c6da #00bcd4 #00acc1
            #0097a7 #00838f #006064 #84ffff #18ffff #00e5ff #00b8d4;

$paletteTeal: #e0f2f1 #b2dfdb #80cbc4 #4db6ac #26a69a #009688 #00897b
            #00796b #00695c #004d40 #a7ffeb #64ffda #1de9b6 #00bfa5;

$paletteGreen: #d0f8ce #a3e9a4 #72d572 #42bd41 #2baf2b #259b24 #0a8f08
             #0a7e07 #056f00 #0d5302 #a2f78d #5af158 #14e715 #12c700;

$paletteLightGreen: #f1f8e9 #dcedc8 #c5e1a5 #aed581 #9ccc65 #8bc34a #7cb342
                  #689f38 #558b2f #33691e #ccff90 #b2ff59 #76ff03 #64dd17;

$paletteLime: #f9fbe7 #f0f4c3 #e6ee9c #dce775 #d4e157 #cddc39 #c0ca33
            #afb42b #9e9d24 #827717 #f4ff81 #eeff41 #c6ff00 #aeea00;

$paletteYellow: #fffde7 #fff9c4 #fff59d #fff176 #ffee58 #ffeb3b #fdd835
              #fbc02d #f9a825 #f57f17 #ffff8d #ffff00 #ffea00 #ffd600;

$paletteAmber: #fff8e1 #ffecb3 #ffe082 #ffd54f #ffca28 #ffc107 #ffb300
             #ffa000 #ff8f00 #ff6f00 #ffe57f #ffd740 #ffc400 #ffab00;

$paletteOrange: #fff3e0 #ffe0b2 #ffcc80 #ffb74d #ffa726 #ff9800 #fb8c00
              #f57c00 #ef6c00 #e65100 #ffd180 #ffab40 #ff9100 #ff6d00;

$paletteDeepOrange: #fbe9e7 #ffccbc #ffab91 #ff8a65 #ff7043 #ff5722 #f4511e
                  #e64a19 #d84315 #bf360c #ff9e80 #ff6e40 #ff3d00 #dd2c00;

$paletteBrown: #efebe9 #d7ccc8 #bcaaa4 #a1887f #8d6e63
             #795548 #6d4c41 #5d4037 #4e342e #3e2723;

$paletteBlueGrey: #eceff1 #cfd8dc #b0bec5 #90a4ae #78909c
                #607d8b #546e7a #455a64 #37474f #263238;

/* ==========  Color definitions  ========== */

$colorRed50:   nth($paletteRed, 1);
$colorRed100:  nth($paletteRed, 2);
$colorRed200:  nth($paletteRed, 3);
$colorRed300:  nth($paletteRed, 4);
$colorRed400:  nth($paletteRed, 5);
$colorRed500:  nth($paletteRed, 6);
$colorRed600:  nth($paletteRed, 7);
$colorRed700:  nth($paletteRed, 8);
$colorRed800:  nth($paletteRed, 9);
$colorRed900:  nth($paletteRed, 10);
$colorRedA100: nth($paletteRed, 11);
$colorRedA200: nth($paletteRed, 12);
$colorRedA400: nth($paletteRed, 13);
$colorRedA700: nth($paletteRed, 14);

$colorPink50:   nth($palettePink, 1);
$colorPink100:  nth($palettePink, 2);
$colorPink200:  nth($palettePink, 3);
$colorPink300:  nth($palettePink, 4);
$colorPink400:  nth($palettePink, 5);
$colorPink500:  nth($palettePink, 6);
$colorPink600:  nth($palettePink, 7);
$colorPink700:  nth($palettePink, 8);
$colorPink800:  nth($palettePink, 9);
$colorPink900:  nth($palettePink, 10);
$colorPinkA100: nth($palettePink, 11);
$colorPinkA200: nth($palettePink, 12);
$colorPinkA400: nth($palettePink, 13);
$colorPinkA700: nth($palettePink, 14);

$colorPurple50:   nth($palettePurple, 1);
$colorPurple100:  nth($palettePurple, 2);
$colorPurple200:  nth($palettePurple, 3);
$colorPurple300:  nth($palettePurple, 4);
$colorPurple400:  nth($palettePurple, 5);
$colorPurple500:  nth($palettePurple, 6);
$colorPurple600:  nth($palettePurple, 7);
$colorPurple700:  nth($palettePurple, 8);
$colorPurple800:  nth($palettePurple, 9);
$colorPurple900:  nth($palettePurple, 10);
$colorPurpleA100: nth($palettePurple, 11);
$colorPurpleA200: nth($palettePurple, 12);
$colorPurpleA400: nth($palettePurple, 13);
$colorPurpleA700: nth($palettePurple, 14);

$colorDeepPurple50:   nth($paletteDeepPurple, 1);
$colorDeepPurple100:  nth($paletteDeepPurple, 2);
$colorDeepPurple200:  nth($paletteDeepPurple, 3);
$colorDeepPurple300:  nth($paletteDeepPurple, 4);
$colorDeepPurple400:  nth($paletteDeepPurple, 5);
$colorDeepPurple500:  nth($paletteDeepPurple, 6);
$colorDeepPurple600:  nth($paletteDeepPurple, 7);
$colorDeepPurple700:  nth($paletteDeepPurple, 8);
$colorDeepPurple800:  nth($paletteDeepPurple, 9);
$colorDeepPurple900:  nth($paletteDeepPurple, 10);
$colorDeepPurpleA100: nth($paletteDeepPurple, 11);
$colorDeepPurpleA200: nth($paletteDeepPurple, 12);
$colorDeepPurpleA400: nth($paletteDeepPurple, 13);
$colorDeepPurpleA700: nth($paletteDeepPurple, 14);

$colorIndigo50:   nth($paletteIndigo, 1);
$colorIndigo100:  nth($paletteIndigo, 2);
$colorIndigo200:  nth($paletteIndigo, 3);
$colorIndigo300:  nth($paletteIndigo, 4);
$colorIndigo400:  nth($paletteIndigo, 5);
$colorIndigo500:  nth($paletteIndigo, 6);
$colorIndigo600:  nth($paletteIndigo, 7);
$colorIndigo700:  nth($paletteIndigo, 8);
$colorIndigo800:  nth($paletteIndigo, 9);
$colorIndigo900:  nth($paletteIndigo, 10);
$colorIndigoA100: nth($paletteIndigo, 11);
$colorIndigoA200: nth($paletteIndigo, 12);
$colorIndigoA400: nth($paletteIndigo, 13);
$colorIndigoA700: nth($paletteIndigo, 14);

$colorBlue50:   nth($paletteBlue, 1);
$colorBlue100:  nth($paletteBlue, 2);
$colorBlue200:  nth($paletteBlue, 3);
$colorBlue300:  nth($paletteBlue, 4);
$colorBlue400:  nth($paletteBlue, 5);
$colorBlue500:  nth($paletteBlue, 6);
$colorBlue600:  nth($paletteBlue, 7);
$colorBlue700:  nth($paletteBlue, 8);
$colorBlue800:  nth($paletteBlue, 9);
$colorBlue900:  nth($paletteBlue, 10);
$colorBlueA100: nth($paletteBlue, 11);
$colorBlueA200: nth($paletteBlue, 12);
$colorBlueA400: nth($paletteBlue, 13);
$colorBlueA700: nth($paletteBlue, 14);

$colorLightBlue50:   nth($paletteLightBlue, 1);
$colorLightBlue100:  nth($paletteLightBlue, 2);
$colorLightBlue200:  nth($paletteLightBlue, 3);
$colorLightBlue300:  nth($paletteLightBlue, 4);
$colorLightBlue400:  nth($paletteLightBlue, 5);
$colorLightBlue500:  nth($paletteLightBlue, 6);
$colorLightBlue600:  nth($paletteLightBlue, 7);
$colorLightBlue700:  nth($paletteLightBlue, 8);
$colorLightBlue800:  nth($paletteLightBlue, 9);
$colorLightBlue900:  nth($paletteLightBlue, 10);
$colorLightBlueA100: nth($paletteLightBlue, 11);
$colorLightBlueA200: nth($paletteLightBlue, 12);
$colorLightBlueA400: nth($paletteLightBlue, 13);
$colorLightBlueA700: nth($paletteLightBlue, 14);

$colorCyan50:   nth($paletteCyan, 1);
$colorCyan100:  nth($paletteCyan, 2);
$colorCyan200:  nth($paletteCyan, 3);
$colorCyan300:  nth($paletteCyan, 4);
$colorCyan400:  nth($paletteCyan, 5);
$colorCyan500:  nth($paletteCyan, 6);
$colorCyan600:  nth($paletteCyan, 7);
$colorCyan700:  nth($paletteCyan, 8);
$colorCyan800:  nth($paletteCyan, 9);
$colorCyan900:  nth($paletteCyan, 10);
$colorCyanA100: nth($paletteCyan, 11);
$colorCyanA200: nth($paletteCyan, 12);
$colorCyanA400: nth($paletteCyan, 13);
$colorCyanA700: nth($paletteCyan, 14);

$colorTeal50:   nth($paletteTeal, 1);
$colorTeal100:  nth($paletteTeal, 2);
$colorTeal200:  nth($paletteTeal, 3);
$colorTeal300:  nth($paletteTeal, 4);
$colorTeal400:  nth($paletteTeal, 5);
$colorTeal500:  nth($paletteTeal, 6);
$colorTeal600:  nth($paletteTeal, 7);
$colorTeal700:  nth($paletteTeal, 8);
$colorTeal800:  nth($paletteTeal, 9);
$colorTeal900:  nth($paletteTeal, 10);
$colorTealA100: nth($paletteTeal, 11);
$colorTealA200: nth($paletteTeal, 12);
$colorTealA400: nth($paletteTeal, 13);
$colorTealA700: nth($paletteTeal, 14);

$colorGreen50:   nth($paletteGreen, 1);
$colorGreen100:  nth($paletteGreen, 2);
$colorGreen200:  nth($paletteGreen, 3);
$colorGreen300:  nth($paletteGreen, 4);
$colorGreen400:  nth($paletteGreen, 5);
$colorGreen500:  nth($paletteGreen, 6);
$colorGreen600:  nth($paletteGreen, 7);
$colorGreen700:  nth($paletteGreen, 8);
$colorGreen800:  nth($paletteGreen, 9);
$colorGreen900:  nth($paletteGreen, 10);
$colorGreenA100: nth($paletteGreen, 11);
$colorGreenA200: nth($paletteGreen, 12);
$colorGreenA400: nth($paletteGreen, 13);
$colorGreenA700: nth($paletteGreen, 14);

$colorLightGreen50:   nth($paletteLightGreen, 1);
$colorLightGreen100:  nth($paletteLightGreen, 2);
$colorLightGreen200:  nth($paletteLightGreen, 3);
$colorLightGreen300:  nth($paletteLightGreen, 4);
$colorLightGreen400:  nth($paletteLightGreen, 5);
$colorLightGreen500:  nth($paletteLightGreen, 6);
$colorLightGreen600:  nth($paletteLightGreen, 7);
$colorLightGreen700:  nth($paletteLightGreen, 8);
$colorLightGreen800:  nth($paletteLightGreen, 9);
$colorLightGreen900:  nth($paletteLightGreen, 10);
$colorLightGreenA100: nth($paletteLightGreen, 11);
$colorLightGreenA200: nth($paletteLightGreen, 12);
$colorLightGreenA400: nth($paletteLightGreen, 13);
$colorLightGreenA700: nth($paletteLightGreen, 14);

$colorLime50:   nth($paletteLime, 1);
$colorLime100:  nth($paletteLime, 2);
$colorLime200:  nth($paletteLime, 3);
$colorLime300:  nth($paletteLime, 4);
$colorLime400:  nth($paletteLime, 5);
$colorLime500:  nth($paletteLime, 6);
$colorLime600:  nth($paletteLime, 7);
$colorLime700:  nth($paletteLime, 8);
$colorLime800:  nth($paletteLime, 9);
$colorLime900:  nth($paletteLime, 10);
$colorLimeA100: nth($paletteLime, 11);
$colorLimeA200: nth($paletteLime, 12);
$colorLimeA400: nth($paletteLime, 13);
$colorLimeA700: nth($paletteLime, 14);

$colorYellow50:   nth($paletteYellow, 1);
$colorYellow100:  nth($paletteYellow, 2);
$colorYellow200:  nth($paletteYellow, 3);
$colorYellow300:  nth($paletteYellow, 4);
$colorYellow400:  nth($paletteYellow, 5);
$colorYellow500:  nth($paletteYellow, 6);
$colorYellow600:  nth($paletteYellow, 7);
$colorYellow700:  nth($paletteYellow, 8);
$colorYellow800:  nth($paletteYellow, 9);
$colorYellow900:  nth($paletteYellow, 10);
$colorYellowA100: nth($paletteYellow, 11);
$colorYellowA200: nth($paletteYellow, 12);
$colorYellowA400: nth($paletteYellow, 13);
$colorYellowA700: nth($paletteYellow, 14);

$colorAmber50:   nth($paletteAmber, 1);
$colorAmber100:  nth($paletteAmber, 2);
$colorAmber200:  nth($paletteAmber, 3);
$colorAmber300:  nth($paletteAmber, 4);
$colorAmber400:  nth($paletteAmber, 5);
$colorAmber500:  nth($paletteAmber, 6);
$colorAmber600:  nth($paletteAmber, 7);
$colorAmber700:  nth($paletteAmber, 8);
$colorAmber800:  nth($paletteAmber, 9);
$colorAmber900:  nth($paletteAmber, 10);
$colorAmberA100: nth($paletteAmber, 11);
$colorAmberA200: nth($paletteAmber, 12);
$colorAmberA400: nth($paletteAmber, 13);
$colorAmberA700: nth($paletteAmber, 14);

$colorOrange50:   nth($paletteOrange, 1);
$colorOrange100:  nth($paletteOrange, 2);
$colorOrange200:  nth($paletteOrange, 3);
$colorOrange300:  nth($paletteOrange, 4);
$colorOrange400:  nth($paletteOrange, 5);
$colorOrange500:  nth($paletteOrange, 6);
$colorOrange600:  nth($paletteOrange, 7);
$colorOrange700:  nth($paletteOrange, 8);
$colorOrange800:  nth($paletteOrange, 9);
$colorOrange900:  nth($paletteOrange, 10);
$colorOrangeA100: nth($paletteOrange, 11);
$colorOrangeA200: nth($paletteOrange, 12);
$colorOrangeA400: nth($paletteOrange, 13);
$colorOrangeA700: nth($paletteOrange, 14);

$colorDeepOrange50:   nth($paletteDeepOrange, 1);
$colorDeepOrange100:  nth($paletteDeepOrange, 2);
$colorDeepOrange200:  nth($paletteDeepOrange, 3);
$colorDeepOrange300:  nth($paletteDeepOrange, 4);
$colorDeepOrange400:  nth($paletteDeepOrange, 5);
$colorDeepOrange500:  nth($paletteDeepOrange, 6);
$colorDeepOrange600:  nth($paletteDeepOrange, 7);
$colorDeepOrange700:  nth($paletteDeepOrange, 8);
$colorDeepOrange800:  nth($paletteDeepOrange, 9);
$colorDeepOrange900:  nth($paletteDeepOrange, 10);
$colorDeepOrangeA100: nth($paletteDeepOrange, 11);
$colorDeepOrangeA200: nth($paletteDeepOrange, 12);
$colorDeepOrangeA400: nth($paletteDeepOrange, 13);
$colorDeepOrangeA700: nth($paletteDeepOrange, 14);

$colorBrown50:   nth($paletteBrown, 1);
$colorBrown100:  nth($paletteBrown, 2);
$colorBrown200:  nth($paletteBrown, 3);
$colorBrown300:  nth($paletteBrown, 4);
$colorBrown400:  nth($paletteBrown, 5);
$colorBrown500:  nth($paletteBrown, 6);
$colorBrown600:  nth($paletteBrown, 7);
$colorBrown700:  nth($paletteBrown, 8);
$colorBrown800:  nth($paletteBrown, 9);
$colorBrown900:  nth($paletteBrown, 10);

$colorBlueGrey50:   nth($paletteBlueGrey, 1);
$colorBlueGrey100:  nth($paletteBlueGrey, 2);
$colorBlueGrey200:  nth($paletteBlueGrey, 3);
$colorBlueGrey300:  nth($paletteBlueGrey, 4);
$colorBlueGrey400:  nth($paletteBlueGrey, 5);
$colorBlueGrey500:  nth($paletteBlueGrey, 6);
$colorBlueGrey600:  nth($paletteBlueGrey, 7);
$colorBlueGrey700:  nth($paletteBlueGrey, 8);
$colorBlueGrey800:  nth($paletteBlueGrey, 9);
$colorBlueGrey900:  nth($paletteBlueGrey, 10);
