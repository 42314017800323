/**
*
* _player-header
*
**/

#header {
    z-index: 1000;
}

.app-bar {
    height: 48px;
    background-color: #D52B1E;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    .logo {
        line-height: 48px !important;
        font-size: 24px !important;
    
        img {
        	max-width:100%;
        	max-height: 100%;
        }
    }
}

.app-bar-container {
    height: inherit !important;
}

#logo, #remote-control, #mainMenu {
    align-self: center;
}

.fileInput {
    display: none !important;
    visibility: none !important;
}

@media all and (max-width: 640px) {
    #ko-buttontemplate {
        display: none !important;
        visibility: none !important;
    }
}

@media all and (max-width: 450px) {
    .app-bar .logo {
    
        img {
display: none !important;
        }
    }
}