#mff-editor {
  display: flex;


    .resize-handle {
      width: 100%;
      height: 12px;
      background: #999;
      cursor: pointer;
    }

    .column {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 72px);
        
        @media #{$bp-mobile} {
            height: calc(100vh - 48px);
        }

        &.first {
            display: block;
            justify-content: flex-end;
            min-width: 33vw;
            max-width: 100vw;
            background: #fff;

            .first-item {
                width: 100%;
                height: 60%;
   
                align-self: center;
                margin-bottom: auto;
                overflow-y: scroll;
                overflow-x: hidden;
            }
        }

        &.second {
            justify-content: flex-start;
            min-width: 0vw;
            width: 33vw;
            max-width: 66vw;
            overflow-x: hidden;
            overflow-y: scroll;
            border-left: 1px solid #999;
            background: #fff;


            .grid-item {
                .title {
                    margin: 0;
                    padding: 4px;
                    font-size: 13px;
                    background: #fafafa;
                    color: #333;
                    border-bottom: 1px solid #f2f2f2;
                }

                .body {
                    padding: 12px;
                }
            }
        }
    }

    .second-column-wrapper {

        position: relative;
        
        .second-column-handle {
            position: absolute !important;
            top: 0;
            right: 0;
        }
    }

}