.matches {
	display: flex;
	width: 100%;

	.matches-first, .matches-second {
		flex-basis: 40%;


		.match1, .match2 {
			box-sizing: border-box;
			width: 100%;
			padding: 8px;
			outline: 1px solid #333;
			user-select: none;

			span {
				-webkit-user-select: none;  
				user-select: none;

			}

		}
	}


}