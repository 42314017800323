/**
*
* _player-exercisewindow	
*
**/


#exerciseWindow {
	display: block;
	width: 100%;
	min-height: 600px;
	margin-top: 64px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 320px;
	border: 0;
}

@media all and (min-width: 990px) {

	#exerciseWindow {

	}

}