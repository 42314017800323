.timeline-controls {

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	.inline {
		display: inline-block;	
	}

	.voice-insert {
		padding: 4px;
		margin-left: 16px;
		border-radius: 4px;
		background: #f8f8f8;
		vertical-align: top;
	}

	.volume {
		width: 100px;
		margin-left: 24px;

		@media #{$bp-mobile} {
            display: none !important;
        }


	}



}