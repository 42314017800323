/**
*
* _player-cards	
*
**/


.card {
	border-radius: 0;
	background-color: #fff;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	padding: 8px;
}
