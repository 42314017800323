#edit-view {
    padding: 24px;

    h3 { 
        margin-top: 0;
    }

button.edit {
    border: 1px solid #333;
    margin-left: 8px;
    cursor: pointer;
}

.side-by-side {
    display: flex;
}


input[type=text],input[type=number], textarea, select {
    width: 80%;
    max-width: 80%;
    padding: 8px;
    margin-bottom: 12px;
    border: 1px solid #333;
    font-family: "Segoe UI", sans-serif;
    display: block;

    &.inline {
        display: inline-block;
        width: 40%;
    }

    &.draft {
        border: 1px dashed #333;
    }
}


textarea.edit-textarea {
    min-height: 100px;
    height: 200px;
    max-height: 300px;
    width: 60%;
    max-width: 60%;
}


select {
    min-width: 40px;
    width: 80%;
    max-width: 80%;
}

.small {
    width: 40% !important;
}

}

.handle {
    display: inline-block;
    width: 24px;
    text-align: center;
    cursor: pointer;
}

.exercise-edit {
    .group {
        margin-left: 24px;
        width: 40%;
        max-width: 40%;

        label, input, select {
            display: block !important;
        }


    }
}