#outline {
    flex-grow: 1;
    margin-right: 20px;
    background: #fff;
    height: 100%;
    min-width: 120px !important;

    button {
        border: 0;
        background: transparent;
        height: 24px;
        width: 24px;
        padding: 4px;
        margin-right: 8px;
        cursor: pointer;
    }

    .control {
        border: 2px solid #333;
        color: #333;
        width: auto;
        height: auto;
        margin-left: 4px;
    }

    .import--file {
        display: none;
    }

    ul {
        text-align: right;
        text-indent: 0;
        padding: 0;
        list-style: none !important;

        li {
            margin: 0 0 2px 0;
            text-align: right;
            transition: all 0.2s;
            position: relative;
            list-style: none !important;
            background-image: none !important;

            &:before {
                content: '' !important;
            }

            &.header--lesson {
                margin-top: 4px;
                background: #D52B1E;
                color: #fff;
                padding: 6px;
                cursor: pointer;

                &.active {
                    position: relative;

                    &:after {
                        left: 100%;
                        top: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-color: rgba(204, 204, 204, 0);
                        border-left-color: #D52B1E;
                        border-width: 20px;
                        margin-top: -20px;
                    }
                }
            }

            &.header--exercise {
                padding: 0;
                background: transparent !important;
                color: #333;

                button {
                   background: #4D4F53; 
                   color: #fff;
                }

                span.exercise-title {
                    display: inline-block;
                    width: calc(100% - 32px);
                    background: #4D4F53;
                    color: #fff;
                    padding: 4px;
                    cursor: pointer;
                }

                &.active {
                    position: relative;

                    span.exercise-title {
                        &:after {
                            transition: all 0.2s;
                            left: 100%;
                            top: 0;
                            border: solid transparent;
                            content: " ";
                            height: 0;
                            width: 0;
                            position: absolute;
                            pointer-events: none;
                            border-color: rgba(204, 204, 204, 0);
                            border-left-color: #4D4F53;
                            border-width: 20px;
                            margin-top: 0px;
                        }
                    }
                }
            }

            &.add-new {
                .title {
                    outline: 1px dashed #333;
                    display: inline-block;
                    width: 100%;
                    background: transparent;
                    color: #333;
                    padding: 0px;
                    cursor: pointer;
                    font-size: 13px; 

                &:hover {
                    background: #f8f8f8;
                }

                }


            }
        }

        &.question-list {
            width: 100%;

            position: relative;

            button {
                   background: #757575 !important; 
                   color: #fff;
                }

            li {
                margin: 0;

                span {
                    display: inline-block;
                    width: calc(90% - 32px);
                    background: #757575;
                    color: #fff;
                    padding: 4px;
                    cursor: pointer;

                }

                &.active {
                    position: relative;

                    &:after {
                        left: 100%;
                        top: 0;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-color: rgba(204, 204, 204, 0);
                        border-left-color: #757575;
                        border-width: 20px;
                        margin-top: 0;
                    
                }
                }                

                &.add-new-question {
                    select {
                        display: inline-block;
                        border: 1px dashed #333;
                        padding: 0px;
                        margin-bottom: 12px;
                        width: calc(90% - 32px);
                        min-width: 50px;
                        background: transparent;
                        color: #333;
                        cursor: pointer;
                        font-size: 13px;
                    
                &:hover {
                    background: #f8f8f8;
                }
                    }



                }
            }
        }
    }
}

li.placeholder {
    background: rgb(255, 240, 120);
}

li.placeholder:before {
    content: "Drop here";
    color: rgb(225, 210, 90);
}
