/**
*
* _player-playerbar
*
**/

.landscape #playerBar {
	flex-direction: column;
	align-content: flex-start;
	padding: 0;
}

#playerBar {
	order: 1;
 	display: flex;
 	position: relative;
 	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	background: #fff;
	border-top: 1px solid #ddd;
	width: 100%;
	margin: 0;
	min-height: 36px;
	padding: 8px 8px 8px 24px;
	font-size: 80%;

#playerBarToggle {
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	background: #ccc;
}

}

	#playerBar--left, #playerBar--right {
		display: flex;
		justify-content: flex-end;
		flex-grow: 1;
		width: 25%;
	}

	#playerBar--left {
		align-self: flex-start;
	}


	#playerBar--right {
   		display: flex;
   		flex-direction: row;
   		align-items: flex-end;
		margin-right: auto;
		min-width: 170px;

		flex-grow: 1;

		label {
			display: inline-block; 
			text-align: middle;

			i {
				color: $coolGray11;
			}
		}

		.halfVertical {
		width: 80% !important;	
	}		

	}

	.landscape {

		#playerBar--right {
			width: 100%;
			border-top: 1px solid #ddd;
			border-bottom: 1px solid #ddd;

			align-items: center !important;
			margin: 2.4rem 0 !important;
			padding: 1.2rem 0;

			.halfVertical {
				width: 80% !important;
			}
		}

		#playerBar--left {
			width: 100%;
		}
	}


	#playerButtons {
		min-width: 190px;
	}

	#playerControls {
		flex-grow: 2;
		flex-shrink: 1;
		width: 50%;
		min-width: 275px;
	}

	.landscape #playerControls {
		width: 100%;
		min-width: 0;
	}


	.barSection {
		margin: 0;
		padding: 0;

		.halflandscape {
			order: 1;
			flex-grow: 1;
			display: inline;
		}

	}
