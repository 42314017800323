/**
*
* _player-vumeter
*
**/


#vu-meter {

width: 20px; 
height: 0;
background: #eee;
margin-right: 24px;
flex-shrink: 0;

}

@media all and (max-width: 640px) {
    #vu-meter {
    	display: none !important;
    }
}