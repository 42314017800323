.bookmark-editor {
	.navigation {
		display: flex;
	
		.bookmark-header {
			z-index: 1000;
			padding: 8px;
			background: #f2f2f2;
			margin-right: 2px;
			cursor: pointer;
			user-select: none; 

			&:last-child {
				margin-right: 0;
			}

			&.active {
				background: #ddd;
			}
		}

	}


	.bookmark-form {
		padding: 16px;
		border: 2px solid #ddd;

		.button {
			padding: 8px;
			margin-left: 4px;
			background: #ddd; 
			cursor: pointer;
		}
	}
}