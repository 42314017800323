#mff-editor  {

    .top-title {
        display: inline-block;
        font-size: 14px;
        padding: 4px;
        color: #fff;
        margin-top: 0;
        background: #ccc;
    }

    .parallel {
        display: flex;
        height: 100%;
    }

    .outline-wrapper {
        position: relative;
        flex-basis: 25%;
         min-width: 140px !important;
        max-height: 100% !important;
        border-bottom: 1px solid #999;
        overflow-y: scroll;
        overflow-x: hidden;
        box-shadow: rgba(0, 0, 0, 0.156863) 0px 3px 10px, rgba(0, 0, 0, 0.227451) 0px 3px 10px;
    }

    #outline {
        flex-basis: 25%;
        flex-grow: 1;
        flex-shrink: 1;
    }

    .edit-view {
        flex-basis: 75%;
        flex-grow: 3;
        flex-shrink: 1;
    }

    .preview {


        .preview-reset {
            float: right;
        }
    }

    .timeline-component {
        border-top: 1px solid #ccc;
        overflow-y: hidden;
        overflow-x: hidden;
    }
}

.gap {
    color: #333;
    background: #333;
}

.details {
    max-width: 1024px;
    margin: auto;
}

::-webkit-scrollbar { 
    height: 10px;

    &:vertical {
        width: 10px;
    }
}



::-webkit-scrollbar-track { 
    background: #fff;
    width: 4px;
 }

::-webkit-scrollbar-track-piece  {

}
::-webkit-scrollbar-thumb        {
    background-color: #666;  
}

::-webkit-scrollbar-corner       { /* 6 */ }
::-webkit-resizer                { /* 7 */ }
@import "grid.scss";
@import "header.scss";
@import "outline.scss";
@import "editview.scss";

//@import "quiz/main.scss";
@import "timeline/main.scss";