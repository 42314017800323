/**
*
* _player-instructions
*
**/

#instructionsWrapper {
	min-height: 48px;
}


#instructions {
	font-size: 120%; 
	padding-left: 48px;
}

.landscape {
	#instructions {
		padding-left: 0;
		margin: 0 auto;
	}
}