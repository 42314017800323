.app-header {
	display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
	width: 100%;
	height: 4vh;
	background: #D52B1E;
	color: #fff;

	h3 {
		display: inline-block;
		margin: 0 auto 0 0;
		padding-left: 24px;
	}

	.buttons {
		margin-left: auto;
		margin-right: 33vw;
		.control {
			border: 2px solid #fff;
			background: #fff;
			color: #333;
		}
	}

}