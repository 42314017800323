.questions {

	width: 100%; 
	.single-question {
		border: 1px solid #f2f2f2;
		padding: 4px;

		.q {
			display: inline-block;
			min-width: 100px;
			width: 40%;
		}

    &.correct {
            input {
                border: 2px solid green !important;
            }
        } 

    .group {
        position: relative;
        overflow: hidden !important;
        z-index: 1;

        input {
            display: inline-block;
            border: 2px solid #bdc3c7;
            border-radius: 6px;
            padding: 4px !important;

            @media #{$bp-mobile} {
                display: block;
                width: 100%;
                margin-top: 6px;
                margin-bottom: 6px;

            }      

        }

        .correct-answer {
        font-size: 80%;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -18px;
    margin-right: -8px;
    vertical-align: top;
    z-index: 200;
    line-height: 1;
    padding: 4px;
    border-radius: 2px;
    background: #5cb85c;
    color: #fff;         
      
        @media #{$bp-mobile} {
            position: relative;
            display: block;
            width: 100%;
            margin-top: 0;
            margin-right: 0;
            z-index: 1;
        }   

        }

    }
	}

input[type=text] {
	width: 40%;
}


}