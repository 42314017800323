.removing {
	opacity: 0.5;
	cursor: not-allowed;
	user-select: none;
	
	button, input, textarea {

	}
}


// An inline edit view for CUD operations
.edit-view {
	@include edit-view;
	background-color: $menu-background;
	border-top: $menu-border;
	border-bottom: $menu-border;
}

.progress {
	width: 24px;
    display: inline-block;
    font-size: 10px;
    text-align: center;
    line-height: 1;
}