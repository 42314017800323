/**
*
* _player-bookmarks	
*
**/

#bookmarks {
	position: relative;
	height: 25px;
	margin: 0;
	background: transparent;
}

.bookmark {
	z-index: 1;
	position: absolute;
	bottom: 0;
	width: 16px;
	height: 16px;
	background-color: $coolGray11;
	mask-image: url(../images/ic_place_24px.svg);
	mask-size: 16px;
	transition: all .2s ease-in-out;
	//border-left: 8px solid transparent;
	//border-right: 8px solid transparent;
	
	//border-top: 16px solid #666;
	//-webkit-box-sizing: border-box;

	}

.bookmark:hover {
	cursor: pointer;
	transform: scale(1.3);
	//border-color: #333 transparent transparent transparent;
}

#newBookmark {
	text-align: left;
}

#addBookmark {
	display: inline !important;
	font-size: 12px;
	line-height: 12px;
	z-index: 200;
}

#displayBookmarkForm {
	display: inline;

	&:hover {
	cursor:pointer;
	}
}

#bookmarkForm {
	display: none;
	width: 100px;

}

#bookmarkForm.display {
	display: inline-block;
}

#bookmarkInput {
display: inline !important;
	width: 40px;
	font-size: 12px;
}
