/**
*
* _player-controls
*
**/

#playerControls {
    text-align: center;
}

.landscape #playerControls {
    padding-top: 2.4rem;
}

#voiceInsertControl {
    display: inline-block;
    margin-left: 24px !important;
    vertical-align: top;

        paper-toggle-button.paper-toggle-button-0[checked] .toggle-bar.paper-toggle-button,
        paper-toggle-button.paper-toggle-button-0[checked] .toggle-button.paper-toggle-button {
            background-color: $sanakoGreen;
        }
        paper-toggle-button.paper-toggle-button-0[checked] .toggle-ink.paper-toggle-button {
            color: $sanakoGreen;
        }
}

.landscape #voiceInsertControl {
    display: block;
    margin-top: 24px;
}

#playerButtons paper-fab {
    display: inline-block !important;
    background: $coolGray11 !important;

    &[disabled] {
        background: $coolGray5 !important;
    }

    .content {
        margin-right: 0 !important;
    }
}

#play {
    margin: 0 6px 0 6px;
}

.navbar-control paper-fab {
    --paper-fab-background: #fff;
}

.button--round {
    display: inline-block !important;
    user-select: none;
    border: 0;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
    position: relative;
    border-radius: 100%;
    font-size: 0;
    padding: 12px;
    margin: 4px;
    -webkit-transition: box-shadow 0.2s, -webkit-transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 500ms, background-color 500ms cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: 50% 50%;

    &:hover {
        box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.3), 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    }

    &:disabled, &[disabled] {
        background: #ddd;
        box-shadow: none;
    }

    &.small {
        padding: 8px;
    }
}

#volumeControls {
    margin-top: 24px;
}

#playerButtons {
    text-align: center;

    #rec {
        background: #D52B1E !important;

        &:disabled {
            background: #D37069 !important;
        }

        &.recording {
            animation-name: rec_animation;
            animation-duration: 4s;
            transform-origin: 70% 70%;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
}

@-webkit-keyframes rec_animation {
    0% {
        -webkit-transform: scale(1);
    }

    30% {
        -webkit-transform: scale(1);
    }

    40% {
        -webkit-transform: scale(1.08);
    }

    50% {
        -webkit-transform: scale(1);
    }

    60% {
        -webkit-transform: scale(1);
    }

    70% {
        -webkit-transform: scale(1);
    }

    80% {
        -webkit-transform: scale(1.08);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

@media all and (max-width: 768px) {
    #playerButtons {
        paper-fab {
            width: 32px;
            height: 32px;
        }
    }
}
