/**
*
* _player-spinner
*
**/

.block {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000 !important;
	background: rgba(0, 0, 0, 0.5); 

	div {
		top: 25%;
		left: 45%;
	}

}

.spinner {
	width: 100%;
	height: 100%;
	opacity: 0.5;
	background: #333;
}