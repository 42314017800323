/**
*
* _player-sliders
*
**/

#volume-sliders {
width: 90%;
min-width: 170px;
}

paper-slider {
	height: 24px;

	width: 80%;
		min-width: 60px;
		max-width: 500px;

	#primaryProgress.paper-progress{
  		background-color: #4D4F53 !important;
	}

	#sliderKnobInner.paper-slider {
  		background-color: #4D4F53 !important;
	}

	.ring #sliderKnobInner.paper-slider {
		background-color: #fff !important;
	}
}

.landscape paper-slider {
		min-width: 80px;
		max-width: 500px;
		width: 80%;
}


input[type='range'] {
    -webkit-appearance: none;
    width: 100%;  
    border-radius: 0;  
    height: 2px;  
    border: 0;
    background-color: #bdc3c7; 

    &::-webkit-slider-runnable-track {

    }

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		background-color: #E0E1DD;
		border: 2px solid #bdc3c7;
		width: 12px;
		height: 12px;
		border-radius: 6px;
		cursor: pointer;
	}

	&:focus {
		&::-webkit-slider-thumb {
			background-color: #fff;
		width: 16px;
		height: 16px;
		border-radius: 8px;				
		}
	}

	&:active {
		&::-webkit-slider-thumb {
			background-color: #fff;
		width: 20px;
		height: 20px;
		border-radius: 10px;				
		}
	}	

	&:disabled {
		&::-webkit-slider-thumb {
		border: 2px solid #bdc3c7;
		outline: 2px solid #eeeeee;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		}	
	}

}
