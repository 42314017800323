/**
*
* _player-toolbar	
*
**/

.navbar-control {
	background-color: #E0E0E0 !important;
	margin-top: 8px;
}

.navbar-control-container {
	max-width: 864px;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
width: 100%;
height: 56px;
position: relative;
-webkit-flex-direction: row;
-ms-flex-direction: row;
flex-direction: row;
margin: 0 auto;	
vertical-align: middle;
}

#toolbarButtons {
	vertical-align: middle;
	margin-top: 8px;
}

#volumeControls {
	background: #fff;
	padding: 8px;
}