.timecode {

	input {
		margin: 0;
		width: 40px;

		&:first-child {
			margin-left: 4px;
		}

		&:last-child {
			margin-right: 4px;
		}
	}


}