 /**
*
* _player-mainmenu
*
**/

#mainMenu {
	

	.disabled {
		pointer-events: none;
		opacity: 0.7;
	}
}
