.chat-handle {
  padding: 12px 0 6px 0;
  cursor: pointer;
}
.chat-container {
  display: flex;
  height: 300px;
  max-height: 500px;
  padding: 24px;
  border: 1px solid #999;
  background: #fff;
  color: #333 !important;


  .chat-left-pane {
    order: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    justify-content: flex-end;
    width: 66%;
    padding: 6px;
  }

  .chat-right-pane {
    flex-grow: 1;
    width: 33%;
    padding: 6px;
    order: 2;
    background: #f5f5f5;
  }

  .chat-messages {
    order: 1;
    overflow-y: scroll;
    overflow-x: auto;

    .message {
      margin-bottom: 6px;

      .chat-handle {

          font-size: 11px;
          font-weight: bold;
      }
    }
  }


  .chat-input {
    order: 2;
    width: 100%;

    input {
      padding: 4px;
      width: 100%;
    }
  }

  .chat-members {
    font-size: 11px;
    line-height: 13px;
  }
}
